import { createContext, useContext } from "react";

export const ALGOLIA_INDEXES = {
  JOURNEY: "journey",
} as const;

export type IndexName = (typeof ALGOLIA_INDEXES)[keyof typeof ALGOLIA_INDEXES];

// Create a context for the Algolia index name
const IndexNameContext = createContext<IndexName | undefined>(undefined);

// Custom hook to access the index name
export const useIndexName = () => {
  const context = useContext(IndexNameContext);
  if (context === undefined) {
    throw new Error("useIndexName must be used within an IndexNameProvider");
  }
  return context;
};

export const IndexNameProvider = IndexNameContext.Provider;
