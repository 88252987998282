import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { getUserInfo } from "services/api/config";

export const useUserInfo = () => {
  const { user } = useAuthInfo();

  const { data: userInfo } = useQuery({
    // Clear cache and re-fetch if userId changes
    queryKey: ["userInfo", user?.userId],
    queryFn: () => getUserInfo(),
    enabled: !!user?.userId,
  });

  return userInfo;
};
