import { useEffect, useState } from "react";
import { downloadFile } from "services/api/files";

export const useFileURL = (fileId: string) => {
  const [fileURL, setFileURL] = useState("");
  const [isFileUrlLoading, setIsFileUrlLoading] = useState(false);
  const [isFileUrlError, setIsFileUrlError] = useState<string | null>(null);

  useEffect(() => {
    if (!fileId) return;

    let url: string | undefined = undefined;
    const fetchFile = async () => {
      setIsFileUrlLoading(true);
      setIsFileUrlError(null);
      try {
        const data = await downloadFile(fileId);
        url = URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
        setFileURL(url);
      } catch (err) {
        setIsFileUrlError("Failed to download the file");
      } finally {
        setIsFileUrlLoading(false);
      }
    };
    fetchFile();

    return () => {
      if (url !== undefined) {
        URL.revokeObjectURL(url);
      }
    };
  }, [fileId]);

  return { fileURL, isFileUrlLoading, isFileUrlError, setFileURL } as const;
};
