import { Route, Routes } from "react-router-dom";
import "assets/stylesheets/App.css";
import PrivacyPolicy from "pages/PrivacyPolicy";
import paths from "constants/paths";
import LogoutRedirect from "pages/LogoutRedirect";
import AuthenticatedApp from "AuthenticatedApp";
import LaunchDarklyProvider from "services/featureFlag/launchDarkly";
import LandingPage from "pages/LandingPage";
import { useAuthInfo } from "@propelauth/react";
import AuthSetupWrapper from "components/layouts/AuthSetupWrapper";

const App = () => {
  const { loading, isLoggedIn } = useAuthInfo();

  return (
    <Routes>
      <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path="/logout-redirect" element={<LogoutRedirect />} />
      <Route
        path="*"
        element={
          loading ? null : !isLoggedIn ? (
            <LandingPage />
          ) : (
            <AuthSetupWrapper>
              <LaunchDarklyProvider>
                <AuthenticatedApp />
              </LaunchDarklyProvider>
            </AuthSetupWrapper>
          )
        }
      />
    </Routes>
  );
};

export default App;
