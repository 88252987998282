import { useTranslation } from "react-i18next";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { useQuery } from "@tanstack/react-query";
import { formatDate } from "utils/date";
import { Panel } from "./SignalReport.styled";
import { getCargoUnitSignalReport } from "services/api/cargoUnit";
import { SignalTypes, SignalType, ReportSignal } from "features/CargoUnitCard/types/signalTypes";
import { signalToIcon } from "features/CargoUnitCard/constants/signals";

const SignalReportPanel = () => {
  const { cargoUnitId } = useCargoUnitStore();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data, isLoading } = useQuery({
    queryFn: () => getCargoUnitSignalReport(cargoUnitId),
    queryKey: ["getCargoUnitSignalReport", cargoUnitId],
    enabled: cargoUnitId !== null,
  });

  const getSignalName = (signal: SignalType) => {
    const signalToName = {
      [SignalTypes.EMPTY_TO_SHIPPER]: "Empty to Shipper",
      [SignalTypes.GATE_IN]: "Gate In",
      [SignalTypes.LOADED_ON_VESSEL]: "Loaded on Vessel",
      [SignalTypes.DEPARTED_FROM_ORIGIN]: "Departed from Origin",
      [SignalTypes.TRANSSHIPMENT_PORT_ARRIVAL]: "Transshipment Port Arrival",
      [SignalTypes.TRANSSHIPMENT_PORT_DEPARTURE]: "Transshipment Port Departure",
      [SignalTypes.ARRIVED_AT_DESTINATION]: "Arrived at Destination",
      [SignalTypes.DISCHARGED_FROM_VESSEL]: "Discharged from Vessel",
      [SignalTypes.GATE_OUT]: "Gate Out",
      [SignalTypes.CONTAINER_RETURNED_EMPTY]: "Container Returned Empty",
    };
    return t(signalToName[signal]);
  };

  return (
    <Panel>
      <div className="signalReport">
        {!isLoading &&
          data?.signals.map((signal: ReportSignal, index: number) => (
            <div className="content" key={index}>
              <img src={signalToIcon[signal.signal]} alt={signal.signal} className="signal-icon" />
              <div className="wrapper" key={index}>
                {index !== 0 && (
                  <div className="signal-spacer">
                    <div className="circle spacer"></div>
                  </div>
                )}
                <div className="signal">
                  <div className="name">{getSignalName(signal.signal)}</div>
                  <div className="details">
                    <span className="detail">
                      {formatDate(new Date(signal.occurredAt), language)}
                    </span>
                    <span className="spacer">|</span>
                    <span className="detail">{`${t("Port of")} ${signal.locationName}`}</span>
                    <span className="spacer">|</span>
                    <span className="detail">{`${t("Vessel")} ${signal.conveyorName}`}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Panel>
  );
};

export default SignalReportPanel;
