import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useManualUploadStore } from "features/ManualUpload/store/manualUploadStore";
import Drawer from "components/Drawer";
import DragAndDropPDF from "../DragAndDropPDF/DragAndDropPDF";
import ContainerInputList from "../ContainerInputList";
import { Content, Header, StartTrackingButton } from "./ManualUploadDrawer.styled";
import UploadIcon from "assets/icons/upload_icon.svg";
import { postContainerNumbers } from "services/api/cargoUnit";
import { postFiles } from "services/api/files";
import ManualUploadPopup from "../ManualUploadPopup/ManualUploadPopup";

const MANUAL_UPLOAD_DRAWER_WIDTH = "680px";
const DRAG_AND_DROP = "dragAndDrop";
const INPUT_CONTAINER = "inputContainer";

const ManualUploadDrawer = () => {
  const { t } = useTranslation();
  const [inputContainer, setInputContainer] = useState("");
  const [pdfFiles, setPdfFiles] = useState<File[]>([]);
  const [isTrackingButtonDisabled, setIsTrackingButtonDisabled] = useState(true);
  const [activeInput, setActiveInput] = useState<
    typeof DRAG_AND_DROP | typeof INPUT_CONTAINER | null
  >(null);
  const { openDrawer, setOpenDrawer } = useManualUploadStore();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>("");
  const [existingContainers, setExistingContainers] = useState<string[]>([]);
  const [newContainers, setNewContainers] = useState<string[]>([]);

  const {
    mutate: addFiles,
    isLoading: isAddingFilesLoading,
    isError: isAddingFilesError,
    isSuccess: isAddingFilesSuccess,
    reset: resetAddFiles,
  } = useMutation({
    onMutate: () => {
      setIsTrackingButtonDisabled(true);
      setOpenPopup(true);
    },
    mutationFn: () => postFiles(pdfFiles),
    onSuccess: () => {
      setPdfFiles([]);
      setActiveInput(null);
    },
    onError: () => setSubmitError("Failed to upload file(s)."),
  });

  const {
    mutate: addContainers,
    isLoading: isAddingContainerLoading,
    isError: isAddingContainersError,
    isSuccess: isAddingContainersSuccess,
    reset: resetAddContainers,
  } = useMutation({
    onMutate: () => {
      setIsTrackingButtonDisabled(true);
      setOpenPopup(true);
    },
    mutationFn: async (inputContainer: string) => {
      // Container numbers at this point should be formatted properly via input container validation.
      // We just need to parse the string.
      const containerNumbers = inputContainer
        .split("\n")
        .map((containerNumber) => containerNumber.trim())
        .filter((containerNumber) => containerNumber !== "");
      return postContainerNumbers(containerNumbers);
    },
    onSuccess: (data) => {
      setExistingContainers(data.existingContainerNumbers);
      setNewContainers(data.newContainerNumbers);
      setInputContainer("");
      setActiveInput(null);
    },
    onError: () => setSubmitError("Failed to upload container number(s)."),
  });

  const onClosePopup = useCallback(() => {
    setOpenPopup(false);
    setIsTrackingButtonDisabled(false);
    setSubmitError("");
    setExistingContainers([]);
    setNewContainers([]);

    // Reset the mutation states so that state variables are reset for next user action.
    resetAddFiles();
    resetAddContainers();
  }, [resetAddFiles, resetAddContainers]);

  const closeDrawer = useCallback(() => {
    onClosePopup();
    setOpenDrawer(false);
  }, [setOpenDrawer, onClosePopup]);

  const handleInputContainerChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setInputContainer(value);
    if (value !== "") {
      setActiveInput(INPUT_CONTAINER);
    } else {
      setActiveInput(null);
    }
  }, []);

  const handleStartTrackingButtonClick = () => {
    if (activeInput === DRAG_AND_DROP) {
      addFiles();
    } else if (activeInput === INPUT_CONTAINER) {
      addContainers(inputContainer);
    }
  };

  return (
    <Drawer openWidth={MANUAL_UPLOAD_DRAWER_WIDTH} isOpen={openDrawer} onClose={closeDrawer}>
      <Content>
        <Header>
          <h3>{t("Manual upload")}</h3>
          <p>
            {t("You can upload one or multiple BLs or enter container numbers in the area below")}.
          </p>
        </Header>
        <DragAndDropPDF
          pdfFiles={pdfFiles}
          setPdfFiles={setPdfFiles}
          disabled={activeInput === INPUT_CONTAINER || openPopup}
          onActivate={setActiveInput}
          onValidationChange={setIsTrackingButtonDisabled}
        />
        <h3>{t("or")}</h3>
        <ContainerInputList
          value={inputContainer}
          onChange={handleInputContainerChange}
          onValidationChange={(isValid) => {
            setIsTrackingButtonDisabled(!isValid);
          }}
          disabled={activeInput === DRAG_AND_DROP || openPopup}
        />
        <StartTrackingButton
          disabled={isTrackingButtonDisabled || openPopup}
          onClick={handleStartTrackingButtonClick}
        >
          <img src={UploadIcon} alt="upload icon" />
          <span>{t("Start Tracking")}</span>
        </StartTrackingButton>
        {openPopup && (
          <ManualUploadPopup
            isLoading={isAddingFilesLoading || isAddingContainerLoading}
            isError={isAddingFilesError || isAddingContainersError}
            uploadErrorMessage={submitError}
            isSuccess={isAddingFilesSuccess || isAddingContainersSuccess}
            onClose={onClosePopup}
            existingContainers={existingContainers}
            newContainers={newContainers}
          />
        )}
      </Content>
    </Drawer>
  );
};

export default ManualUploadDrawer;
