import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Index } from "react-instantsearch";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { ALGOLIA_INDEXES, IndexNameProvider } from "hooks/useIndexName";
import { JOURNEY_VIEWS, JourneyViewProvider } from "hooks/useJourneyView";
import FilterDropdown from "components/FilterDropdown";
import CurrentFilters from "components/CurrentFilters";
import ActionButtons from "components/ActionButtons";
import AlgoliaConfigure from "components/AlgoliaConfigure/AlgoliaConfigure";
import ArchivedButton from "components/AlgoliaArchivedButton";
import Search from "components/Search";
import ExcelExportButton from "components/ExcelExportButton";
import AlgoliaCargoUnitTable from "features/CargoUnit/components/AlgoliaCargoUnitTable";
import CargoUnitCard from "features/CargoUnitCard";
import UploadDocumentsButton from "features/ManualUpload/components/ManualUploadButton";
import UploadDocumentsDrawer from "features/ManualUpload/components/ManualUploadDrawer";
import { attributesDisplay, filterAttributes } from "features/CargoUnit/constants/filters";
import SaveSearchButton from "components/SaveSearchButton";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import { useTenantInfo } from "hooks/useTenantInfo";

// TODO @piercebb implement BackendCargoUnitTable when USE_BACKEND_RECORDS is true
const CargoUnitView = () => {
  const { t } = useTranslation();
  const featureFlags = useFeatureFlags();

  useDocumentTitle(t("Container"));

  const tenantInfo = useTenantInfo();

  // Remove division filter if tenant does not use divisions
  const filteredAttributes = useMemo(() => {
    if (tenantInfo?.usesDivisions) {
      return filterAttributes.map((filterAttribute) => ({
        ...filterAttribute,
        attributes: filterAttribute.attributes.filter(
          (attribute) => attribute.attribute !== "division",
        ),
      }));
    }
    return filterAttributes;
  }, [tenantInfo?.usesDivisions]);

  return (
    <JourneyViewProvider value={JOURNEY_VIEWS.FULL_JOURNEY}>
      <IndexNameProvider value={ALGOLIA_INDEXES.JOURNEY}>
        <Index indexName={ALGOLIA_INDEXES.JOURNEY}>
          <AlgoliaConfigure />
          <ActionButtons title="Container">
            <Search />
            <SaveSearchButton />
            <FilterDropdown filterAttributes={filteredAttributes} />
            <ArchivedButton />
            <ExcelExportButton />
            {featureFlags[FeatureFlagNames.EnableJourneyManualUpload] && <UploadDocumentsButton />}
          </ActionButtons>
          <CurrentFilters attributesDisplay={attributesDisplay} />
          <AlgoliaCargoUnitTable />
          <CargoUnitCard />
          <UploadDocumentsDrawer />
        </Index>
      </IndexNameProvider>
    </JourneyViewProvider>
  );
};

export default CargoUnitView;
