export const logisticsVocabularyEnglish = {
  Archived: "Archived",
  Vendor: "Vendor",
  Carrier: "Carrier",
  Documents: "Documents",
  Reference: "Reference",
  Container: "Container",
  Destination: "Destination",
  "Email Ingestion": "Email Ingestion",
  "Log Out": "Log Out",
  "Please select your email provider": "Please select your email provider",
  Provider: "Provider",
  "Oops! Looks like something went wrong. Please restart the authorization flow.":
    "Oops! Looks like something went wrong. Please restart the authorization flow.",
  "Authorization for email ingestion succeeded!": "Authorization for email ingestion succeeded!",
  "Processing...": "Processing...",
  "BL Number": "BL Number",
  "Bills of Lading": "Bills of Lading",
  "BL Date": "BL Date",
  Origin: "Origin",
  "Current Mode of Transport": "Current Mode of Transport",
  Mode: "Mode",
  Ship: "Ship",
  Ocean: "Ocean",
  Ground: "Ground",
  Customs: "Customs",
  Air: "Air",
  Rail: "Rail",
  "Customs Broker": "Customs Broker",
  Status: "Status",
  "Original ETA": "Original ETA",
  "Current ETA": "Current ETA",
  "ETA Delay": "ETA Delay",
  Tracking: "Tracking",
  Untracked: "Untracked",
  "To Be Shipped": "To Be Shipped",
  "Loaded on Vessel": "Loaded on Vessel",
  "Awaiting Data": "Awaiting Data",
  Loaded: "Loaded",
  "In Transit": "In Transit",
  Arrived: "Arrived",
  "Arrived In Destination": "Arrived in Destination",
  Discharged: "Discharged",
  "Discharged from Vessel": "Discharged from Vessel",
  "day(s)": "day(s)",
  "Journey Start": "Journey Start",
  "Shipping Line": "Shipping Line",
  "Welcome back": "Welcome back",
  Loading: "Loading",
  "An error occurred. Please try again later": "An error occurred. Please try again later",
  "Updated ETA": "Updated ETA",
  "Transport Type": "Transport Type",
  day: "day",
  "New ETA": "New ETA",
  Cargo: "Cargo",
  "Cargo Number": "Cargo Number",
  "Pre Arrival": "Pre Arrival",
  "The file cannot be displayed in your browser": "The file cannot be displayed in your browser",
  Upload: "Upload",
  Export: "Export",
  Trips: "Trips",
  "What are you looking for?": "What are you looking for?",
  Start: "Start",
  Contents: "Contents",
  "Uploading your files": "Uploading your files",
  [`Please be patient, this may take a few minutes.
    We will send you an email once the process is complete.
    You may close this message.`]: `Please be patient, this may take a few minutes.
    We will send you an email once the process is complete.
    You may close this message.`,
  Filter: "Filter",
  "Add Filter To Trips": "Add Filter To Trips",
  "Add Filter To Legs": "Add Filter To Legs",
  "Active Leg Mode Of Transport": "Active Leg Mode Of Transport",
  "Origin City": "Origin City",
  "Destination City": "Destination City",
  City: "City",
  Country: "Country",
  "Select Company": "Select Company",
  "No options available": "No options available",
  "Active Filters": "Active Filters",
  "Document Not Yet Received": "Document Not Yet Received",
  "We have not yet received the": "We have not yet received the",
  "for this leg.": "for this leg.",
  Collapse: "Collapse",
  Insights: "Insights",
  Showing: "Showing",
  "out of": "out of",
  shipments: "shipments",
  "Show More": "Show More",
  "Show Less": "Show Less",
  Division: "Division",
  End: "End",
  Released: "Released",
  Departed: "Departed",
  Delivered: "Delivered",
  "Invoice Number": "Invoice Number",
  Invoices: "Invoices",
  "Other Invoice": "Other Invoice",
  "Rail Tracking": "Rail Tracking",
  "Preview not supported for this file type yet": "Preview not supported for this file type yet",
  Download: "Download",
  "Departure Country": "Departure Country",
  "Departure City": "Departure City",
  "Endpoint Country": "Endpoint Country",
  "Endpoint City": "Endpoint City",
  "Endpoint BL": "Endpoint BL",
  "Departure Date": "Departure Date",
  "Entry City": "Entry City",
  "Entry BL": "Entry BL",
  "Entry Carrier": "Entry Carrier",
  "Entry Original ETA": "Entry Original ETA",
  "Entry Current ETA": "Entry Current ETA",
  "Entry ATA": "Entry ATA",
  "Entry Mode of Transport": "Entry Mode of Transport",
  "Customs Document Number": "Customs Document Number",
  "Customs Release ETA": "Customs Release ETA",
  "Customs Release ATA": "Customs Release ATA",
  "Endpoint Carrier": "Endpoint Carrier",
  "Endpoint Original ETA": "Endpoint Original ETA",
  "Endpoint Current ETA": "Endpoint Current ETA",
  "Endpoint ATA": "Endpoint ATA",
  "Days Without Delay": "Days Without Delay",
  "Container Return Date": "Container Return Date",
  "See ETA Logs": "See ETA Logs",
  "Container Contents": "Container Contents",
  Milestones: "Milestones",
  "Save View": "Save View",
  "Dates marked with an asterisk (*) are estimated based on available information":
    "Dates marked with an asterisk (*) are estimated based on available information",
  "Entered Customs": "Entered Customs",
  "Vessel Name": "Vessel Name",
  "Container Number": "Container Number",
  "Bill of Lading Numbers": "Bill of Lading Numbers",
  "Latest Milestone": "Latest Milestone",
  "Purchase Orders": "Purchase Orders",
  "Invoice Numbers": "Invoice Numbers",
  "Port of Departure": "Port of Departure",
  "Destination Country": "Destination Country",
  "Estimated Departure Date": "Estimated Departure Date",
  "Actual Departure Date": "Actual Departure Date",
  "Original Discharge ETA": "Original Discharge ETA",
  "Current Discharge ETA": "Current Discharge ETA",
  "Port of Destination": "Port of Destination",
  "Port of Destination ATA": "Port of Destination ATA",
  "Actual Discharge Date": "Actual Discharge Date",
  "Total ETA Delay (days)": "Total ETA Delay (days)",
  "Manual Upload": "Manual Upload",
  "You can upload a maximum of {{max}} PDF files.":
    "You can upload a maximum of {{max}} PDF files.",
  "{{count}} PDF file(s) uploaded successfully!": "{{count}} PDF file(s) uploaded successfully!",
  "Please upload only PDF files.": "Please upload only PDF files.",
  "Search locally or simply drag and drop the BLs to start tracking your containers.":
    "Search locally or simply drag and drop the BLs to start tracking your containers.",
  "Manual upload": "Manual upload",
  "You can upload one or multiple BLs or enter container numbers in the area below":
    "You can upload one or multiple BLs or enter container numbers in the area below",
  "Enter container numbers, one per line.": "Enter container numbers, one per line.",
  "Start Tracking": "Start Tracking",
  or: "or",
  "Documents Uploaded": "Documents Uploaded",
  "Total Size: {{size}}MB": "Total Size: {{size}}MB",
  "Total file size cannot exceed {{max}}MB.": "Total file size cannot exceed {{max}}MB.",
  "Line {{line}}: {{error}}": "Line {{line}}: {{error}}",
  "Invalid format for container number.": "Invalid format for container number.",
  "Received on": "Received on",
  "Event History": "Event History",
  "Port of": "Port of",
  Vessel: "Vessel",
  "Empty to Shipper": "Empty to Shipper",
  "Gate In": "Gate In",
  "Departed from Origin": "Departed from Origin",
  "Transshipment Port Arrival": "Transshipment Port Arrival",
  "Transshipment Port Departure": "Transshipment Port Departure",
  "Arrived at Destination": "Arrived at Destination",
  "Gate Out": "Gate Out",
  "Container Returned Empty": "Container Returned Empty",
  Search: "Search for",
  "Max values selected": "Max values selected",
  containerNumberHelperText:
    "Correct format: 3 uppercase letters (Owner Code), followed by 'U', 'J', or 'Z' (Category Identifier), 6 numeric digits (Serial Number), and 1 check digit. Example: CSQU3054383.",
  "Failed to upload file(s).": "Failed to upload file(s).",
  "Failed to upload container number(s).": "Failed to upload container number(s).",
  "Encountered an error submitting container information. Please wait a while and try again.":
    "Encountered an error submitting container information. Please wait a while and try again.",
  "We are currently processing your data to begin tracking your containers.":
    "We are currently processing your data to begin tracking your containers.",
  "Containers Scheduled for Tracking:": "Containers Scheduled for Tracking:",
  "Containers Already Being Tracked:": "Containers Already Being Tracked:",
  "You will receive an email notification once processing is complete and tracking commences.":
    "You will receive an email notification once processing is complete and tracking commences.",
  Close: "Close",
  Processing: "Processing",
  "Transshipment Arrival": "Transshipment Arrival",
  "Transshipment Departure": "Transshipment Departure",
  "Pedimento Paid": "Pedimento Paid",
  Inspection: "Inspection",
  Scanned: "Scanned",
  "Clear All": "Clear All",
  "Proforma Invoice": "Proforma Invoice",
  "Purchase Order": "Purchase Order",
  "Packing List": "Packing List",
  "Certificate of Origin": "Certificate of Origin",
  "Commercial Invoice": "Commercial Invoice",
  Waybill: "Waybill",
  "Bill of Lading": "Bill of Lading",
  "Mexican Pedimento": "Mexican Pedimento",
  "Arrival Notice": "Arrival Notice",
  "Advice of Shipment": "Advice of Shipment",
  "Payment Receipt": "Payment Receipt",
  Labels: "Labels",
  Other: "Other",
  "Maersk Shipping Report": "Maersk Shipping Report",
  "Elektra Maersk Report Row": "Elektra Maersk Report Row",
  "LG Shipment Report": "LG Shipment Report",
  "LG Shipment Report Row": "LG Shipment Report Row",
  "Serial Numbers": "Serial Numbers",
  journeys: "journeys",
  archived: "archived",
  active: "active",
};

export const logisticsVocabularySpanish: {
  [k in keyof typeof logisticsVocabularyEnglish]: string;
} = {
  Archived: "Archivado",
  Vendor: "Proveedor",
  Carrier: "Transportador",
  Documents: "Documentos",
  Reference: "Referencia",
  Container: "Contenedor",
  Destination: "Destino",
  "Email Ingestion": "Ingestión de correo",
  "Log Out": "Cerrar sesión",
  "Please select your email provider": "Seleccione su proveedor de correo electrónico",
  Provider: "Proveedor",
  "Oops! Looks like something went wrong. Please restart the authorization flow.":
    "Oops! Parece que algo salió mal. Vuelva a iniciar la autorización.",
  "Authorization for email ingestion succeeded!":
    "¡La autorización de ingestión de correo electrónico tuvo éxito!",
  "Processing...": "Procesando...",
  "BL Number": "Número de BL",
  "Bills of Lading": "Bills of lading",
  "BL Date": "Fecha de BL",
  Origin: "Origen",
  "Current Mode of Transport": "Modo de transporte actual",
  Mode: "Modo",
  Ship: "Barco",
  Ocean: "Marítimo",
  Ground: "Terrestre",
  Customs: "Aduana",
  Air: "Aereo",
  Rail: "Ferroviario",
  "Customs Broker": "Agente aduanal",
  Status: "Estado",
  "Original ETA": "Original ETA",
  "Current ETA": "Actual ETA",
  "ETA Delay": "ETA Retraso",
  Tracking: "Seguimiento",
  Untracked: "Sin seguimiento",
  "To Be Shipped": "Por enviar",
  "Loaded on Vessel": "Cargado en barco",
  "Awaiting Data": "Esperando datos",
  Loaded: "Cargado",
  "In Transit": "En tránsito",
  Arrived: "Llegada",
  "Arrived In Destination": "Llegada al destino",
  Discharged: "Descargado",
  "Discharged from Vessel": "Descargado de Barco",
  "day(s)": "día(s)",
  "Journey Start": "Inicio del viaje",
  "Shipping Line": "Línea de envío",
  "Welcome back": "Bienvenido de nuevo",
  Loading: "Cargando",
  "An error occurred. Please try again later":
    "Ocurrió un error. Por favor, inténtelo de nuevo más tarde",
  "Updated ETA": "ETA actualizada",
  "Transport Type": "Tipo de Transporte",
  day: "día",
  "New ETA": "Nueva ETA",
  Cargo: "Carga",
  "Cargo Number": "Número de carga",
  "Pre Arrival": "Pre llegada",
  "The file cannot be displayed in your browser": "El archivo no se puede mostrar en su navegador",
  Upload: "Subir",
  Export: "Exportar",
  Trips: "Viajes",
  "What are you looking for?": "¿Qué estás buscando?",
  Start: "Inicio",
  Contents: "Contenidos",
  "Uploading your files": "Subiendo tus archivos",
  [`Please be patient, this may take a few minutes.
    We will send you an email once the process is complete.
    You may close this message.`]: `Por favor, tenga paciencia, esto puede tardar unos minutos.
    Le enviaremos un correo electrónico una vez que el proceso esté completo. Puede cerrar este mensaje.`,
  Filter: "Filtrar",
  "Add Filter To Trips": "Agregar Filtro A Viajes",
  "Add Filter To Legs": "Agregar Filtro A Tramos",
  "Active Leg Mode Of Transport": "Modo De Transporte De Tramo Activo",
  "Origin City": "Ciudad Do Origen",
  "Destination City": "Ciudad De Destino",
  City: "Ciudad",
  Country: "País",
  "Select Company": "Seleccionar Compañía",
  "No options available": "Sin opciones",
  "Active Filters": "Filtros Activos",
  "Document Not Yet Received": "Documento No Recibido Aún",
  "We have not yet received the": "Aún no hemos recibido el",
  "for this leg.": "para este tramo.",
  Collapse: "Colapsar",
  Insights: "Informes",
  Showing: "Mostrando",
  "out of": "de",
  shipments: "envíos",
  "Show More": "Mostrar Más",
  "Show Less": "Mostrar Menos",
  Division: "División",
  End: "Fin",
  Released: "Liberado",
  Departed: "Salida",
  Delivered: "Entregado",
  "Invoice Number": "Número de factura",
  Invoices: "Facturas",
  "Other Invoice": "Otra factura",
  "Rail Tracking": "Localización de Carros",
  "Preview not supported for this file type yet":
    "Vista previa aún no disponible para este tipo de archivo",
  Download: "Descargar",
  "Departure Country": "País de salida",
  "Departure City": "Ciudad de salida",
  "Endpoint Country": "País de destino",
  "Endpoint City": "Ciudad de destino",
  "Endpoint BL": "BL de destino",
  "Departure Date": "Fecha de salida",
  "Entry City": "Ciudad de entrada",
  "Entry BL": "BL de entrada",
  "Entry Carrier": "Transportador de entrada",
  "Entry Original ETA": "ETA original de entrada",
  "Entry Current ETA": "ETA actual de entrada",
  "Entry ATA": "ATA de entrada",
  "Entry Mode of Transport": "Modo de transporte de entrada",
  "Customs Document Number": "Número de documento de aduana",
  "Customs Release ETA": "ETA de liberación de aduana",
  "Customs Release ATA": "ATA de liberación de aduana",
  "Endpoint Carrier": "Transportador de destino",
  "Endpoint Original ETA": "ETA original de destino",
  "Endpoint Current ETA": "ETA actual de destino",
  "Endpoint ATA": "ATA de destino",
  "Days Without Delay": "Días sin retraso",
  "Container Return Date": "Fecha de retorno de contenedor",
  "See ETA Logs": "Ver registros de ETA",
  "Container Contents": "Contenido del contenedor",
  Milestones: "Hitos",
  "Save View": "Guardar Vista",
  "Dates marked with an asterisk (*) are estimated based on available information":
    "Las fechas marcadas con un asterisco (*) son estimadas en base a la información disponible",
  "Entered Customs": "Ingreso en Aduana",
  "Vessel Name": "Nombre del Barco",
  "Container Number": "Número de Contenedor",
  "Bill of Lading Numbers": "Números de BL",
  "Latest Milestone": "Último Hito",
  "Purchase Orders": "Órdenes de Compra",
  "Invoice Numbers": "Números de Factura",
  "Port of Departure": "Puerto de Salida",
  "Destination Country": "País de Destino",
  "Estimated Departure Date": "Fecha de Salida Estimada",
  "Actual Departure Date": "Fecha de Salida Actual",
  "Original Discharge ETA": "ETA de Descarga Original",
  "Current Discharge ETA": "ETA de Descarga Actual",
  "Port of Destination": "Puerto de Destino",
  "Port of Destination ATA": "ATA de Puerto de Destino",
  "Actual Discharge Date": "Fecha de Descarga Actual",
  "Total ETA Delay (days)": "Retraso Total de ETA (días)",
  "Manual Upload": "Carga Manual",
  "You can upload a maximum of {{max}} PDF files.":
    "Puede cargar un máximo de {{max}} archivos PDF.",
  "{{count}} PDF file(s) uploaded successfully!":
    "{{count}} archivo(s) PDF cargado(s) correctamente!",
  "Please upload only PDF files.": "Por favor, suba solo archivos PDF.",
  "Search locally or simply drag and drop the BLs to start tracking your containers.":
    "Busque localmente o simplemente arrastre y suelte los BLs para comenzar a rastrear sus contenedores.",
  "Manual upload": "Carga manual",
  "You can upload one or multiple BLs or enter container numbers in the area below":
    "Puede cargar uno o varios BLs o ingresar números de contenedor en el área a continuación",
  "Enter container numbers, one per line.": "Ingrese números de contenedor, uno por línea.",
  "Start Tracking": "Comenzar a rastrear",
  or: "ó",
  "Documents Uploaded": "Documentos Cargados",
  "Total Size: {{size}}MB": "Tamaño total: {{size}} MB",
  "Total file size cannot exceed {{max}}MB.":
    "El tamaño total de los archivos no puede exceder los {{max}}MB.",
  "Line {{line}}: {{error}}": "Línea {{line}}: {{error}}",
  "Invalid format for container number.": "Formato inválido para el número de contenedor.",
  "Received on": "Recibido el",
  "Event History": "Historial de Eventos",
  "Port of": "Puerto de",
  Vessel: "Barco",
  "Empty to Shipper": "Vacío al Embarcador",
  "Gate In": "Acceso de Entrada",
  "Departed from Origin": "Salida de Origen",
  "Transshipment Port Arrival": "Llegada a Puerto de Transshipment",
  "Transshipment Port Departure": "Salida de Puerto de Transshipment",
  "Arrived at Destination": "Llegada al Destino",
  "Gate Out": "Salida de Puerta",
  "Container Returned Empty": "Contenedor Devuelto Vacío",
  Search: "Buscar",
  "Max values selected": "Valores máximos seleccionados",
  containerNumberHelperText:
    "Formato correcto: 3 letras mayúsculas (Código de propietario), seguido de 'U', 'J' o 'Z' (Identificador de categoría), 6 dígitos numéricos (Número de serie) y 1 dígito de verificación. Ejemplo: CSQU3054383.",
  "Failed to upload file(s).": "No se pudo cargar el(los) archivo(s).",
  "Failed to upload container number(s).": "No se pudo cargar el número de contenedor(es).",
  "Encountered an error submitting container information. Please wait a while and try again.":
    "Se produjo un error al enviar la información del contenedor. Espere un momento y vuelva a intentarlo.",
  "We are currently processing your data to begin tracking your containers.":
    "Actualmente estamos procesando sus datos para comenzar a rastrear sus contenedores.",
  "Containers Scheduled for Tracking:": "Contenedores programados para seguimiento:",
  "Containers Already Being Tracked:": "Contenedores ya siendo rastreados:",
  "You will receive an email notification once processing is complete and tracking commences.":
    "Recibirá una notificación por correo electrónico una vez que se complete el procesamiento y comience el seguimiento.",
  Close: "Cerrar",
  Processing: "Procesando",
  "Transshipment Arrival": "Llegada Transshipment",
  "Transshipment Departure": "Salida Transshipment",
  "Pedimento Paid": "Pedimento Pagado",
  Inspection: "Inspección",
  Scanned: "Escaneado",
  "Clear All": "Limpiar todo",
  "Proforma Invoice": "Factura Proforma",
  "Purchase Order": "Orden de Compra",
  "Packing List": "Packing List",
  "Certificate of Origin": "Certificado de Origen",
  "Commercial Invoice": "Factura Comercial",
  Waybill: "Waybill",
  "Bill of Lading": "Bill of Lading",
  "Mexican Pedimento": "Pedimento de Importación",
  "Arrival Notice": "Notificación de Arrivo",
  "Advice of Shipment": "Aviso de Embarque",
  "Payment Receipt": "Recibo de pago",
  Labels: "Etiquetas",
  Other: "Otros",
  "Maersk Shipping Report": "Reporte de envíos de Maersk",
  "Elektra Maersk Report Row": "Reporte Elektra Maersk",
  "LG Shipment Report": "LG reporte de envíos",
  "LG Shipment Report Row": "LG Reporte por fila",
  "Serial Numbers": "Números de serie",
  journeys: "trayectos",
  archived: "archivados",
  active: "activos",
} as const;
