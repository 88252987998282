import { useEffect, useState } from "react";
import { useActiveTenantStore } from "store/activeTenantStore";
import { Outlet } from "react-router-dom";
import { Container, Main } from "./Layout.styled";
import { changeLanguage } from "i18next";
import Sidebar from "../Sidebar";
import AlgoliaSearchWrapper from "components/AlgoliaSearchWrapper";
import { useUserInfo } from "hooks/useUserInfo";

const Layout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const { activeTenant, setActiveTenant } = useActiveTenantStore();

  const userInfo = useUserInfo();

  useEffect(() => {
    const langMap: { [key: string]: string } = { english: "en", spanish: "es" };
    if (userInfo?.language) {
      changeLanguage(langMap[userInfo.language]);
    }
    // Checks if original activeTenant is an accessible tenant
    // If not, set to first accessible tenant (relevant to SSO login)
    if (userInfo?.accessibleTenants?.length) {
      const activeTenantIsAccessible = userInfo.accessibleTenants.some(
        (accessibleTenant) => accessibleTenant.name === activeTenant,
      );
      if (!activeTenantIsAccessible) {
        setActiveTenant(userInfo.accessibleTenants[0].name, true);
      }
    }
  }, [userInfo, activeTenant, setActiveTenant]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // TODO: Add a loading spinner
  if (!activeTenant) return null;

  return (
    <Container data-testid="layout">
      <Sidebar collapsed={collapsed} handleCollapse={handleCollapse} />
      <Main collapsed={collapsed}>
        <AlgoliaSearchWrapper>
          <Outlet />
        </AlgoliaSearchWrapper>
      </Main>
    </Container>
  );
};

export default Layout;
