import { useEffect } from "react";
import { useActiveTenantStore } from "store/activeTenantStore";
import { useAuthInfo } from "@propelauth/react";
import { getCookie, setCookie } from "utils/cookies";

export const useManageActiveTenant = () => {
  const { user, orgHelper } = useAuthInfo();
  const { setActiveTenant, activeTenant } = useActiveTenantStore();

  useEffect(() => {
    const activeUserEmail = getCookie("activeUserEmail");
    const storedTenant = getCookie("activeTenant");
    if (user?.email) {
      if (user.email !== activeUserEmail) {
        // New or different user has logged in
        setCookie("activeUserEmail", user.email);

        // Reset the active tenant for the new user if it was previously set
        if (activeTenant) {
          setActiveTenant("", false);
        } else if (!storedTenant && orgHelper) {
          // Set the tenant to the first organization name
          const firstOrgName = orgHelper.getOrgs()[0]?.orgName;
          setActiveTenant(firstOrgName, false);
        }
      } else if (storedTenant && storedTenant !== activeTenant) {
        // User is the same, but the stored tenant differs from the current state
        setActiveTenant(storedTenant, false);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error("No user email");
    }
  }, [user, activeTenant, setActiveTenant, orgHelper]);
};
