import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { getTenantInfo } from "services/api/config";
import { useActiveTenantStore } from "store/activeTenantStore";

export const useTenantInfo = () => {
  const { user } = useAuthInfo();
  const { activeTenant } = useActiveTenantStore();

  const { data: tenantInfo } = useQuery({
    // Clear cache and re-fetch if userId or activeTenant changes
    queryKey: ["tenantInfo", user?.userId, activeTenant],
    queryFn: () => getTenantInfo(),
    enabled: !!user?.userId && !!activeTenant,
  });

  return tenantInfo;
};
