import apiClient from "./apiClient";

export async function getUserInfo(): Promise<{
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  language?: "english" | "spanish";
  accessibleTenants?: { id: string; name: string; humanReadableName: string }[];
}> {
  const res = await apiClient.get("/config/user-info");
  return res.data;
}

export async function getTenantInfo(): Promise<{
  id: string;
  name: string;
  humanReadableName: string;
  profilePhoto: { content?: string; contentType?: string };
  logoutUrl: string;
  usesDivisions: boolean;
}> {
  const res = await apiClient.get("/config/tenant-info");
  return res.data;
}

export async function getLocalFeatureFlags() {
  const res = await apiClient.get("/config/feature-flags");
  return res.data;
}

export async function updateUserInfo(data: {
  firstName?: string;
  lastName?: string;
  language?: "english" | "spanish";
}) {
  const res = await apiClient.patch("/config/user-info", data);
  return res.data;
}
