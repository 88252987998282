import axios from "axios";
import config from "config";
import { useAccessTokenStore } from "store/accessTokenStore";
import { getCookie } from "utils/cookies";

const apiClient = axios.create({
  baseURL: config.envs.BACKEND_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    const tenantName = getCookie("activeTenant");
    if (tenantName) {
      config.headers["auba-tenant"] = tenantName;
    }

    const accessToken = useAccessTokenStore.getState().storedAccessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default apiClient;
