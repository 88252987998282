import apiClient from "./apiClient";
import { CargoUnitDetails } from "types/cargoUnitTypes";
import { CargoUnitSignalReport } from "features/CargoUnitCard/types/signalTypes";

export async function getCargoUnitDetails(
  cargoUnitId: string | null,
  milestoneModeOfTransportFilter?: string[],
): Promise<CargoUnitDetails | null> {
  if (!cargoUnitId) return null;

  const res = await apiClient.get<CargoUnitDetails>(`/journeys/records/${cargoUnitId}/details`, {
    params: { milestoneModeOfTransportFilter },
  });

  return { ...res.data };
}

interface PostContainerNumbersResponse {
  newContainerNumbers: string[];
  existingContainerNumbers: string[];
}

export async function postContainerNumbers(
  cargoUnitIds: string[],
): Promise<PostContainerNumbersResponse> {
  const res = await apiClient.post<PostContainerNumbersResponse>("/journeys/container-numbers", {
    containerNumbers: cargoUnitIds,
  });

  return { ...res.data };
}

export async function getCargoUnitSignalReport(
  cargoUnitId: string | null,
  // query: {type: string, addOns: string[]},
): Promise<CargoUnitSignalReport | null> {
  if (!cargoUnitId) return null;
  // TODO: add query params
  const res = await apiClient.get<CargoUnitSignalReport>(
    `/journeys/records/${cargoUnitId}/signal-report`,
    {
      // params: query,
    },
  );

  return { ...res.data };
}
