import { CargoUnit, CargoUnitHitProps } from "types/cargoUnitTypes";
import { formatDate } from "./date";

export function sanitizeAlgoliaCargoUnits(
  algoliaRecords: CargoUnitHitProps[],
  language: string,
): CargoUnit[] {
  const records: CargoUnit[] = [];

  for (const record of algoliaRecords) {
    records.push({
      ...record,
      id: record.objectID,
      entryBLNumbers: record.entryBLNumbers?.join(", "),
      endpointBLNumbers: record.endpointBLNumbers?.join(", "),
      purchaseOrderNumbers: record.purchaseOrderNumbers?.join(", "),
      invoiceNumbers: record.invoiceNumbers?.join(", "),
      oceanDepartureETA: record.oceanDepartureETA
        ? formatDate(new Date(record.oceanDepartureETA), language)
        : undefined,
      oceanDepartureATA: record.oceanDepartureATA
        ? formatDate(new Date(record.oceanDepartureATA), language)
        : undefined,
      oceanOriginalDischargeETA: record.oceanOriginalDischargeETA
        ? formatDate(new Date(record.oceanOriginalDischargeETA), language)
        : undefined,
      oceanCurrentDischargeETA: record.oceanCurrentDischargeETA
        ? formatDate(new Date(record.oceanCurrentDischargeETA), language)
        : undefined,
      oceanDischargeATA: record.oceanDischargeATA
        ? formatDate(new Date(record.oceanDischargeATA), language)
        : undefined,
      oceanArrivalATA: record.oceanArrivalATA
        ? formatDate(new Date(record.oceanArrivalATA), language)
        : undefined,
      departureDate: record.departureDate
        ? formatDate(new Date(record.departureDate), language)
        : undefined,
      entryOriginalETA: record.entryOriginalETA
        ? formatDate(new Date(record.entryOriginalETA), language)
        : undefined,
      entryCurrentETA: record.entryCurrentETA
        ? formatDate(new Date(record.entryCurrentETA), language)
        : undefined,
      entryATA: record.entryATA ? formatDate(new Date(record.entryATA), language) : undefined,
      entryDestinationATA: record.entryDestinationATA
        ? formatDate(new Date(record.entryDestinationATA), language)
        : undefined,
      customsReleaseETA: record.customsReleaseETA
        ? formatDate(new Date(record.customsReleaseETA), language)
        : undefined,
      customsReleaseATA: record.customsReleaseATA
        ? formatDate(new Date(record.customsReleaseATA), language)
        : undefined,
      endpointOriginalETA: record.endpointOriginalETA
        ? formatDate(new Date(record.endpointOriginalETA), language)
        : undefined,
      endpointCurrentETA: record.endpointCurrentETA
        ? formatDate(new Date(record.endpointCurrentETA), language)
        : undefined,
      endpointATA: record.endpointATA
        ? formatDate(new Date(record.endpointATA), language)
        : undefined,
      containerReturnDate: record.containerReturnDate
        ? formatDate(new Date(record.containerReturnDate), language)
        : undefined,
    });
  }

  return records;
}
