import { create } from "zustand";

type AccessTokenStore = {
  storedAccessToken: string;
  setAccessToken: (accessToken: string) => void;
};

export const useAccessTokenStore = create<AccessTokenStore>((set) => ({
  storedAccessToken: "",
  setAccessToken: (accessToken: string) => set({ storedAccessToken: accessToken }),
}));
