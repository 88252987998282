import { useCallback, useMemo } from "react";
import UntrackedTooltip from "../../../components/UntrackedTooltip";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { CargoUnit } from "types/cargoUnitTypes";
import { capitalize } from "utils/string";
import SortableHeader from "components/SortableHeader";

export const useCargoUnitColumns = () => {
  const { t } = useTranslation();

  const translate = useCallback((key: string) => t(key) as string, [t]);

  const columns = useMemo<ColumnDef<CargoUnit>[]>(
    () => [
      {
        header: translate("Cargo Number"),
        accessorKey: "cargoId",
      },
      {
        header: translate("Current Mode of Transport"),
        accessorKey: "currentModeOfTransport",
        accessorFn: (row) =>
          row.currentModeOfTransport ? translate(capitalize(row.currentModeOfTransport)) : "",
      },
      {
        header: translate("Division"),
        accessorKey: "division",
      },
      {
        header: translate("Purchase Order"),
        accessorKey: "purchaseOrderNumbers",
      },
      {
        header: translate("Invoice Number"),
        accessorKey: "invoiceNumbers",
      },
      {
        header: translate("Vendor"),
        accessorKey: "vendor",
      },
      {
        header: translate("Departure Country"),
        accessorKey: "departureCountry",
        cell: (props) => translate(props.getValue<string>()),
      },
      {
        header: translate("Departure City"),
        accessorKey: "departureCity",
      },
      {
        header: translate("Endpoint Country"),
        accessorKey: "endpointCountry",
        cell: (props) => translate(props.getValue<string>()),
      },
      {
        header: translate("Endpoint City"),
        accessorKey: "endpointCity",
      },
      {
        header: () => (
          <SortableHeader
            label="Departure Date"
            algoliaKey="journey_departure_date"
            sortKey="departureDate"
          />
        ),
        accessorKey: "departureDate",
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.departureUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        header: translate("Entry City"),
        accessorKey: "entryCity",
      },
      {
        header: translate("Entry BL"),
        accessorKey: "entryBLNumbers",
      },
      {
        header: translate("Entry Carrier"),
        accessorKey: "entryCarrier",
      },
      {
        header: translate("Entry Mode of Transport"),
        accessorKey: "entryModeOfTransport",
      },
      {
        header: () => (
          <SortableHeader
            label="Entry Original ETA"
            algoliaKey="journey_entry_original_eta"
            sortKey="entryOriginalETA"
          />
        ),
        accessorKey: "entryOriginalETA",
      },
      {
        header: () => (
          <SortableHeader
            label="Entry Current ETA"
            algoliaKey="journey_entry_current_eta"
            sortKey="entryCurrentETA"
          />
        ),
        accessorKey: "entryCurrentETA",
      },
      {
        header: () => (
          <SortableHeader label="Entry ATA" algoliaKey="journey_entry_ata" sortKey="entryATA" />
        ),
        accessorKey: "entryATA",
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.entryUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        header: translate("Customs Document Number"),
        accessorKey: "customsDocumentNumber",
      },
      {
        header: () => (
          <SortableHeader
            label="Customs Release ETA"
            algoliaKey="journey_customs_release_eta"
            sortKey="customsReleaseETA"
          />
        ),
        accessorKey: "customsReleaseETA",
      },
      {
        header: () => (
          <SortableHeader
            label="Customs Release ATA"
            algoliaKey="journey_customs_release_ata"
            sortKey="customsReleaseATA"
          />
        ),
        accessorKey: "customsReleaseATA",
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.customsReleaseUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        header: translate("Customs Broker"),
        accessorKey: "customsBroker",
      },
      {
        header: translate("Endpoint BL"),
        accessorKey: "endpointBLNumbers",
      },
      {
        header: translate("Endpoint Carrier"),
        accessorKey: "endpointCarrier",
      },
      {
        header: () => (
          <SortableHeader
            label="Endpoint Original ETA"
            algoliaKey="journey_endpoint_original_eta"
            sortKey="endpointOriginalETA"
          />
        ),
        accessorKey: "endpointOriginalETA",
      },
      {
        header: () => (
          <SortableHeader
            label="Endpoint Current ETA"
            algoliaKey="journey_endpoint_current_eta"
            sortKey="endpointCurrentETA"
          />
        ),
        accessorKey: "endpointCurrentETA",
      },
      {
        header: () => (
          <SortableHeader
            label="Endpoint ATA"
            algoliaKey="journey_endpoint_ata"
            sortKey="endpointATA"
          />
        ),
        accessorKey: "endpointATA",
        cell: (props) => {
          const value = props.getValue<string>();
          const isEnabled = Boolean(value) && (props.row.original.endpointUntracked ?? false);

          return <UntrackedTooltip enabled={isEnabled}>{value}</UntrackedTooltip>;
        },
      },
      {
        header: translate("Days Without Delay"),
        accessorKey: "daysWithoutDelay",
      },
      {
        header: () => (
          <SortableHeader
            label="Container Return Date"
            algoliaKey="journey_container_return_date"
            sortKey="containerReturnDate"
          />
        ),
        accessorKey: "containerReturnDate",
      },
      {
        header: translate("Alerts"),
        accessorKey: "alertCount",
      },
      {
        header: translate("ID"),
        accessorKey: "id",
      },
    ],
    [translate],
  );

  return columns;
};
