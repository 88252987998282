import { useMutation, useQuery } from "@tanstack/react-query";
import { getSavedFilter, upsertFilter } from "services/api/filters";

export const useFetchSavedUiState = () =>
  useQuery({
    queryFn: () => getSavedFilter(),
    queryKey: ["getSavedUiState"],
  });

export const useSetSavedUiState = () =>
  useMutation({
    mutationFn: ({ uiState }: { uiState: string }) => upsertFilter(uiState),
  });
