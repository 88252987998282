import { useTranslation } from "react-i18next";
import { FileViewer, NotSupported } from "./FilePreview.styled";
import { hasExcelExtension, hasPdfExtension, hasWordExtension } from "utils/string";
import ExcelIcon from "assets/icons/excel_icon.svg";
import WordIcon from "assets/icons/word_icon.svg";
import DownloadIcon from "assets/icons/download_icon.svg";
import { downloadFile } from "services/api/files";

type FilePreviewProps = {
  fileId: string;
  fileName: string;
  fileURL: string;
};

const FilePreview = ({ fileId, fileName, fileURL }: FilePreviewProps) => {
  const { t } = useTranslation();

  const getDownloadFileIcon = (fileName: string) => {
    if (hasExcelExtension(fileName)) {
      return ExcelIcon;
    } else if (hasWordExtension(fileName)) {
      return WordIcon;
    } else return DownloadIcon;
  };

  const handleDownloadClick = async () => {
    const file = await downloadFile(fileId);
    const url = URL.createObjectURL(file);
    window.open(url, "_blank");
  };

  if (!hasPdfExtension(fileName)) {
    return (
      <>
        <NotSupported>
          <p>{t("Preview not supported for this file type yet")}.</p>
          <a title={fileName} onClick={handleDownloadClick}>
            <img src={getDownloadFileIcon(fileName)} alt="File Icon" className="fileIcon" />
            <p>{t("Download")}</p>
          </a>
        </NotSupported>
      </>
    );
  }

  if (fileId && fileURL === "") {
    return <p>{t("Loading")}</p>;
  }

  return (
    <FileViewer>
      <iframe src={fileURL} title="file" allowFullScreen>
        <p>{t("The file cannot be displayed in your browser")}</p>
      </iframe>
    </FileViewer>
  );
};

export default FilePreview;
