import { capitalize } from "@mui/material";
import FilterButton from "components/FilterButton";
import { useTranslation } from "react-i18next";
import { UseRefinementListReturn } from "./types";
import { FilterCategorySection } from "./FilterDropdown.styled";

type FilterCategoryDisplayProps = {
  filterItemsDisplay?: Record<string, string>;
  useRefinementListReturn: UseRefinementListReturn;
};

const FilterCategoryDisplay = ({
  filterItemsDisplay,
  useRefinementListReturn,
}: FilterCategoryDisplayProps) => {
  const { items, refine } = useRefinementListReturn;
  const { t } = useTranslation();

  return (
    <FilterCategorySection>
      {items.length ? (
        items.map((item, i) => (
          <FilterButton
            key={item.label + i}
            text={capitalize(filterItemsDisplay?.[item.label] || item.label)}
            active={item.isRefined}
            onClick={() => {
              refine(item.value);
            }}
          />
        ))
      ) : (
        <div>{t("No options available")}</div>
      )}
    </FilterCategorySection>
  );
};

export default FilterCategoryDisplay;
