import React from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto", padding: "16px", overflowY: "auto" }}>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: "16px" }} data-testid="header">
        Privacy and Security Policy
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <b>Last updated: February 12, 2025</b>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        Auba Inc. (“Desteia”) collects personal data as part of its products and services
        (“Services”). Your privacy and the privacy of all our users is very important to us. Please
        read this Privacy Policy to learn how we treat your personal data.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        By using or accessing our Services in any manner, you acknowledge that you accept the
        practices and policies outlined below, and you hereby consent that we will collect, use, and
        share your information as described in this Privacy Policy.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        What this Privacy Policy Covers
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        This Privacy Policy covers how we treat Personal Data that we gather when you access or use
        our Services. “Personal Data” means any information that identifies or relates to a
        particular individual and includes information referred to as “personally identifiable
        information” or “personal information” under applicable data privacy laws, rules or
        regulations. This Privacy Policy does not cover the practices of companies we don’t own or
        control or people we don’t manage.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Personal Data
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Categories of Personal Data We Collect</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        This chart details the categories of Personal Data that we collect and have collected over
        the past 12 months:
      </Typography>
      <TableContainer sx={{ marginBottom: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Category of Personal Data</b>
              </TableCell>
              <TableCell>
                <b>Examples of Personal Data We Collect</b>
              </TableCell>
              <TableCell>
                <b>Categories of Third Parties with Whom We Share this Personal Data</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Profile or Contact Data</TableCell>
              <TableCell>
                <ul>
                  <li>First and last name</li>
                  <li>Email address</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service providers</li>
                  <li>Parties you authorize, access or authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Online Identifiers</TableCell>
              <TableCell>
                <ul>
                  <li>Unique identifiers such as account names and passwords</li>
                  <li>Security questions and answers</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service providers</li>
                  <li>Parties you authorize, access or authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Web Analytics</TableCell>
              <TableCell>
                <ul>
                  <li>Webpage interactions</li>
                  <li>Non-identifiable request IDs</li>
                  <li>IP addresses</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service providers</li>
                  <li>Parties you authorize, access or authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Device IP/Data</TableCell>
              <TableCell>
                <ul>
                  <li>Device ID</li>
                  <li>Domain server</li>
                  <li>Type of device/operating system/browser used to access the Services</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service providers</li>
                  <li>Parties you authorize, access or authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Other Identifying Information that You Voluntarily Choose to Provide
              </TableCell>
              <TableCell>
                <ul>
                  <li>
                    Identifying information submitted by you in emails, messages, survey responses,
                    videos or other content you upload to the Services
                  </li>
                </ul>
              </TableCell>
              <TableCell>
                <ul>
                  <li>Service providers</li>
                  <li>Parties you authorize, access or authenticate</li>
                </ul>
              </TableCell>
            </TableRow>
            {/* Add more rows as needed */}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="body1" gutterBottom sx={{ marginTop: "16px", marginBottom: "8px" }}>
        <i>Categories of Sources of Personal Data</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We collect Personal Data about you from the following categories of sources:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <ul>
          <li>You</li>
          <ul>
            <li>When you provide such information directly to us.</li>
            <li>When you create an account or use our Services.</li>
            <li>
              When you voluntarily provide information in free-form text boxes through the Services
              or through responses to surveys or questionnaires.
            </li>
            <li>When you send us an email or otherwise contact us.</li>
            <li>When you use the Services and such information is collected automatically.</li>
            <li>
              Through Cookies (defined in the “Tracking Tools, Advertising and Opt-Out” section
              below).
            </li>
          </ul>
          <li>Third Parties</li>
          <ul>
            <li>Your Employer</li>
            <ul>
              <li>
                Through the use of our Services, your Employer may disclose your Personal Data with
                us.
              </li>
            </ul>
            <li>Vendors</li>
            <ul>
              <li>
                We may use analytics providers to analyze how you interact and engage with the
                Services, or third parties may help us provide you with customer support.
              </li>
            </ul>
            <li>Social Networks</li>
            <ul>
              <li>
                If you provide your social network account credentials to us or otherwise sign in to
                the Services through a third-party site or service, content and/or information in
                those accounts may be transmitted into your account with us (including your posts,
                status updates, messages, and any user-generated content you upload).
              </li>
            </ul>
          </ul>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: "16px", marginBottom: "8px" }}>
        <i>Our Commercial or Business Purposes for Collecting Personal Data</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <ul>
          <li>Providing, Customizing and Improving the Services</li>
          <ul>
            <li>Creating and managing your account. </li>
            <li>Processing orders; billing.</li>
            <li>Providing you with the products, services or information you request.</li>
            <li>Meeting or fulfilling the reason you provided the information to us.</li>
            <li>Providing support and assistance for the Services.</li>
            <li>
              Improving the Services, including testing, research, internal analytics and product
              development.
            </li>
            <li>
              Personalizing the Services, website content and communications based on your
              preferences.
            </li>
            <li>Doing fraud protection, security and debugging.</li>
            <li>
              Carrying out other business purposes stated when collecting your Personal Data or as
              otherwise set forth in applicable data privacy laws.
            </li>
          </ul>
          <li>Marketing the Services</li>
          <ul>
            <li>Marketing and selling the Services. </li>
          </ul>
          <li>Corresponding with You</li>
          <ul>
            <li>
              Responding to correspondence that we receive from you, contacting you when necessary
              or requested, and sending you information about Desteia or the Services.
            </li>
            <li>Sending emails and other communications according to your preferences.</li>
          </ul>
          <li>Meeting Legal Requirements and Enforcing Legal Terms</li>
          <ul>
            <li>
              Fulfilling our legal obligations under applicable law, regulation, court order or
              other legal process, such as preventing, detecting and investigating security
              incidents and potentially illegal or prohibited activities.
            </li>
            <li>Protecting the rights, property or safety of you, Desteia or another party.</li>
            <li>Enforcing any agreements with you.</li>
            <li>
              Responding to claims that any posting or other content violates third-party rights.
            </li>
            <li>Resolving disputes.</li>
          </ul>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We will not collect additional categories of Personal Data or use the Personal Data we
        collected for materially different, unrelated or incompatible purposes without providing you
        notice.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        How We Share Your Personal Data
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We disclose your Personal Data to the categories of service providers and other parties
        listed in this section. Depending on state laws that may be applicable to you, some of these
        disclosures may constitute a “sale” of your Personal Data. For more information, please
        refer to the state-specific sections below.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <ul>
          <li>
            Service Providers. These parties help us provide the Services or perform business
            functions on our behalf. They include:
          </li>
          <ul>
            <li>Hosting, technology and communication providers.</li>
            <li>Security and fraud prevention consultants.</li>
            <li>Support and customer service vendors.</li>
            <li>Payment processors.</li>
          </ul>
          <li>
            Analytics Partners. These parties provide analytics on web traffic or usage of the
            Services. They include:
          </li>
          <ul>
            <li>Companies that track how users found or were referred to the Services.</li>
            <li>Companies that track how users interact with the Services.</li>
          </ul>
          <li>Parties You Authorize, Access or Authenticate</li>
          <ul>
            <li>Third parties you access through the services.</li>
            <li>Social media services.</li>
          </ul>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Legal Obligations</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We may share any Personal Data that we collect with third parties in conjunction with any of
        the activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the
        “Our Commercial or Business Purposes for Collecting Personal Data” section above.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Business Transfers</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        All of your Personal Data that we collect may be transferred to a third party if we undergo
        a merger, acquisition, bankruptcy or other transaction in which that third party assumes
        control of our business (in whole or in part). Should one of these events occur, we will
        make reasonable efforts to notify you before your information becomes subject to different
        privacy and security policies and practices.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Data that is Not Personal Data</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We may create aggregated, de-identified or anonymized data from the Personal Data we
        collect, including by removing information that makes the data personally identifiable to a
        particular user. We may use such aggregated, de-identified or anonymized data and share it
        with third parties for our lawful business purposes, including to analyze, build and improve
        the Services and promote our business, provided that we will not share such data in a manner
        that could identify you.{" "}
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Tracking Tools, Advertising and Opt-Out
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        The Services use cookies and similar technologies such as pixel tags, web beacons, clear
        GIFs and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web
        browser, tell us how and when you visit and use our Services, analyze trends, learn about
        our user base and operate and improve our Services. Cookies are small pieces of data –
        usually text files – placed on your computer, tablet, phone or similar device when you use
        that device to access our Services. We may also supplement the information we collect from
        you with information received from third parties, including third parties that have placed
        their own Cookies on your device(s). Please note that because of our use of Cookies, the
        Services do not support “Do Not Track” requests sent from a browser at this time.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We use the following types of Cookies:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <ul>
          <li>
            Essential Cookies. Essential Cookies are required for providing you with features or
            services that you have requested. For example, certain Cookies enable you to log into
            secure areas of our Services. Disabling these Cookies may make certain features and
            services unavailable.
          </li>
          <li>
            Functional Cookies. Functional Cookies are used to record your choices and settings
            regarding our Services, maintain your preferences over time and recognize you when you
            return to our Services. These Cookies help us to personalize our content for you, greet
            you by name and remember your preferences (for example, your choice of language or
            region).{" "}
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        You can decide whether or not to accept Cookies through your internet browser’s settings.
        Most browsers have an option for turning off the Cookie feature, which will prevent your
        browser from accepting new Cookies, as well as (depending on the sophistication of your
        browser software) allow you to decide on acceptance of each new Cookie in a variety of ways.
        You can also delete all Cookies that are already on your device. If you do this, however,
        you may have to manually adjust some preferences every time you visit our website and some
        of the Services and functionalities may not work.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        To explore what Cookie settings are available to you, look in the “preferences” or “options”
        section of your browser’s menu. To find out more information about Cookies, including
        information about how to manage and delete Cookies, please visit{" "}
        <a href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Information about Interest-Based Advertisements</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We may allow third-party ad networks, including third-party ad servers, ad agencies, ad
        technology vendors and research firms, to serve advertisements regarding the Services. Our
        Services do not host advertisements of any third parties. These advertisements may be
        targeted to users who fit certain general profile categories or display certain preferences
        or behaviors (“Interest-Based Ads”). Information for Interest-Based Ads (including Personal
        Data) may be provided to us by you, or derived from the usage patterns of particular users
        on the Services and/or services of third parties. Such information may be gathered through
        tracking users’ activities across time and unaffiliated properties, including when you leave
        the Services. To accomplish this, we or our service providers may deliver Cookies, including
        a file (known as a “web beacon”) from an ad network to you through the Services. Web beacons
        allow ad networks to provide anonymized, aggregated auditing, research and reporting for us
        and for advertisers. Web beacons also enable ad networks to serve targeted advertisements to
        you when you visit other websites. Web beacons allow ad networks to view, edit or set their
        own Cookies on your browser, just as if you had requested a web page from their site.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We comply with the Digital Advertising Alliance (“DAA”) Self-Regulatory Principles for
        Online Behavioral Advertising. Through the DAA and Network Advertising Initiative (“NAI”),
        several media and marketing associations have developed an industry self-regulatory program
        to give consumers a better understanding of, and greater control over, ads that are
        customized based a consumer’s online behavior across different websites and properties. To
        make choices about Interest-Based Ads from participating third parties, including to opt-out
        of receiving behaviorally targeted advertisements from participating organizations, please
        visit the DAA’s or NAI’s consumer opt-out pages, which are located at{" "}
        <a href="https://www.networkadvertising.org/choices/">
          https://www.networkadvertising.org/choices/
        </a>{" "}
        or <a href="https://www.aboutads.info/choices">https://www.aboutads.info/choices</a>.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Data Security and Retention
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We seek to protect your Personal Data from unauthorized access, use and disclosure using
        appropriate physical, technical, organizational and administrative security measures based
        on the type of Personal Data and how we are processing that data. You should also help
        protect your data by appropriately selecting and protecting your password and/or other
        sign-on mechanism; limiting access to your computer or device and browser; and signing off
        after you have finished accessing your account. Although we work to protect the security of
        your account and other data that we hold in our records, please be aware that no method of
        transmitting data over the internet or storing data is completely secure.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We retain Personal Data about you for as long as you have an open account with us or as
        otherwise necessary to provide you with our Services. In some cases we retain Personal Data
        for longer, if doing so is necessary to comply with our legal obligations, resolve disputes
        or collect fees owed, or is otherwise permitted or required by applicable law, rule or
        regulation. We may further retain information in an anonymous or aggregated form where that
        information would not identify you personally.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Personal Data of Children
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We do not knowingly collect or solicit Personal Data about children under 16 years of age;
        if you are a child under the age of 16, please do not attempt to register for or otherwise
        use the Services or send us any Personal Data. If we learn we have collected Personal Data
        from a child under 16 years of age, we will delete that information as quickly as possible.
        If you believe that a child under 16 years of age may have provided Personal Data to us,
        please contact us at support@desteia.com.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        State Law Privacy Rights
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>California Resident Rights</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to
        contact us to prevent disclosure of Personal Data to third parties for such third parties’
        direct marketing purposes; in order to submit such a request, please contact us at
        support@desteia.com.{" "}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        <i>Nevada Resident Rights</i>
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        If you are a resident of Nevada, you have the right to opt out of the sale of certain
        Personal Data to third parties who intend to license or sell that Personal Data. You can
        exercise this right by contacting us at support@desteia.com with the subject line “Nevada Do
        Not Sell Request” and providing us with your name and the email address associated with your
        account.
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Changes to this Privacy Policy
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        We’re constantly trying to improve our Services, so we may need to change this Privacy
        Policy from time to time, but we will alert you to any such changes by placing a notice on
        the Desteia website, by sending you an email and/or by some other means. Please note that if
        you’ve opted not to receive legal notice emails from us (or you haven’t provided us with
        your email address), those legal notices will still govern your use of the Services, and you
        are still responsible for reading and understanding them. If you use the Services after any
        changes to the Privacy Policy have been posted, that means you agree to all of the changes.
        Use of information we collect is subject to the Privacy Policy in effect at the time such
        information is collected.{" "}
      </Typography>
      <Typography variant="h5" gutterBottom sx={{ marginBottom: "8px", marginTop: "16px" }}>
        Contact Information
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        If you have any questions or comments about this Privacy Policy, the ways in which we
        collect and use your Personal Data or your choices and rights regarding such collection and
        use, please do not hesitate to contact us at:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        Email: support@desteia.com
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        If you believe you’ve discovered a potential vulnerability, please let us know by emailing
        us at:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginBottom: "8px" }}>
        Email: security@desteia.com
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
