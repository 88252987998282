import { useEffect } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";
import { FeatureFlagNames, useFeatureFlags } from "hooks/useFeatureFlags";
import RailTracking from "pages/RailTracking";
import EmailIngestion from "pages/EmailIngestion";
import CargoUnitView from "pages/CargoUnitView";
import Layout from "components/layouts/Layout";
import paths from "constants/paths";
import CompleteEmailIngestion from "pages/CompleteEmailIngestion";
import Insights from "pages/Insights";
import KpisPage from "pages/KpisPage";
import OceanViewPage from "pages/OceanView";

import config from "config";
import "assets/stylesheets/App.css";
import LandingPage from "pages/LandingPage";
import { useTenantInfo } from "hooks/useTenantInfo";

const AMPLITUDE_GROUP_TYPE_TENANT = "tenant_id";

const AuthenticatedApp = () => {
  const { loading, isLoggedIn, user } = useAuthInfo();
  const tenantInfo = useTenantInfo();

  useEffect(() => {
    if (!user || !tenantInfo) {
      return;
    }

    if (
      config.envs.AMPLITUDE_DISABLE_FOR_INTERNAL_USERS === "true" &&
      /auba\.ai$/.test(user.email)
    ) {
      // eslint-disable-next-line no-console
      console.log("[amplitude]", "Disabling for internal user");
      return;
    }

    if (config.envs.AMPLITUDE_API_KEY) {
      amplitude.init(config.envs.AMPLITUDE_API_KEY, {
        userId: user.userId,
        defaultTracking: true,
      });

      /*
        We overwrite the group value every time a user switches to a new tenant. Which means:
          - Amplitude will unify the events under the same user profile (because the user_id is the same), but each event carries a different group/tenant context.
          - For group/tenant analytics, the user will “count” toward whichever group/tenant they were in at the time they triggered the event.
          - A user’s single event will not count toward multiple groups/tenants at the same time.
        
          FYI - The user’s profile page will end up showing only the last tenant_id assigned.
      */
      amplitude.setGroup(AMPLITUDE_GROUP_TYPE_TENANT, tenantInfo.id);
    }
  }, [user, tenantInfo]);

  const featureFlags = useFeatureFlags();

  // Feature Flags - add new feature flags here
  const isCargoUnitFrontendEnabled = featureFlags[FeatureFlagNames.EnableCargoUnitFrontend];
  const isETALogsFrontendEnabled = featureFlags[FeatureFlagNames.EnableETALogsFrontend];
  const isOceanViewPageEnabled = featureFlags[FeatureFlagNames.EnableOceanViewPage];
  const isRailTrackingTabEnabled = featureFlags[FeatureFlagNames.EnableRailTrackingTab];

  // Default route based on feature flags
  const defaultRoute = isCargoUnitFrontendEnabled ? paths.cargoUnit : paths.insights;

  // App.tsx should handle loading and redirect to landing page
  // but just in case, we handle here too
  if (loading) return null;
  if (!isLoggedIn) return <LandingPage />;

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to={defaultRoute} />} />
        <Route path={paths.completeAuthorization} element={<CompleteEmailIngestion />} />
        <Route path={paths.insights} element={<Insights />} />

        {/* Conditionally render routes based on feature flags */}
        <Route
          path={paths.kpis}
          element={isETALogsFrontendEnabled ? <KpisPage /> : <Navigate to={defaultRoute} />}
        />
        <Route
          path={paths.emailIngestion}
          element={<EmailIngestion choices={[{ name: "Outlook" }, { name: "Gmail" }]} />}
        />
        <Route
          path={paths.railTracking}
          element={isRailTrackingTabEnabled ? <RailTracking /> : <Navigate to={defaultRoute} />}
        />
        <Route
          path={paths.cargoUnit}
          element={isCargoUnitFrontendEnabled ? <CargoUnitView /> : <Navigate to={defaultRoute} />}
        />
        <Route
          path={paths.oceanView}
          element={isOceanViewPageEnabled ? <OceanViewPage /> : <Navigate to={defaultRoute} />}
        />
        {/* ADD HERE MORE PROTECTED ROUTES */}
      </Route>
      {/* TODO: handle this more gracefully? */}
      <Route path="*" element={<Navigate to={defaultRoute} />} />
    </Routes>
  );
};

export default AuthenticatedApp;
