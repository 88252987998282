import apiClient from "./apiClient";
import config from "config";

export const getAlgoliaSearchAPIKey = async () => {
  // do !== false instead of === true so that "default" is true
  const useAlgoliaAuth = config.envs.USE_ALGOLIA_AUTH !== "false";
  if (useAlgoliaAuth) {
    const res = await apiClient.get<{ key: string }>("/search/key");

    if (!res.data) {
      throw new Error("No data found");
    }

    return res.data.key;
  } else {
    return config.envs.ALGOLIA_LOCAL_SEARCH_API_KEY;
  }
};
