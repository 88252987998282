"use client";

import React, { useEffect, useState } from "react";
import { LDContext, LDProvider } from "launchdarkly-react-client-sdk";
import { useTenantInfo } from "hooks/useTenantInfo";

import config from "config";

const useFFMock = config.envs.USE_FEATURE_FLAG_MOCK !== "false";
const { LAUNCHDARKLY_CLIENT_ID } = config.envs;

type LaunchDarklyProviderProps = {
  children: React.ReactNode;
};

const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({ children }) => {
  const [ldContext, setLDContext] = useState<LDContext | undefined>(undefined);

  const tenantInfo = useTenantInfo();

  useEffect(() => {
    if (!tenantInfo?.id || !tenantInfo.name) {
      return;
    }

    if (!ldContext || tenantInfo?.id !== ldContext.key || ldContext.name !== tenantInfo.name) {
      const LDContext: LDContext = {
        kind: "tenant",
        key: tenantInfo.id,
        name: tenantInfo.name,
        tenantName: tenantInfo.name,
      };

      setLDContext(LDContext);
    }
  }, [tenantInfo, ldContext]);

  if (useFFMock) {
    return <>{children}</>;
  } else if (!ldContext) {
    return null;
  } else {
    return (
      <LDProvider
        clientSideID={LAUNCHDARKLY_CLIENT_ID}
        reactOptions={{
          useCamelCaseFlagKeys: false,
        }}
        options={{
          streaming: true,
        }}
        timeout={2}
        deferInitialization={true}
        context={ldContext}
      >
        {children}
      </LDProvider>
    );
  }
};

export default LaunchDarklyProvider;
