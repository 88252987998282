import { CloseButton, MoreDetailsContainer } from "./MoreDetailsDrawer.styled";
import CloseIcon from "assets/icons/close_icon.svg";

type MoredDetailsCardProps = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
};

const MoreDetails = ({ show, onClose, children }: MoredDetailsCardProps) => {
  return (
    <MoreDetailsContainer show={show}>
      <CloseButton className="closeBtn" onClick={onClose} aria-label="Close Content">
        <img src={CloseIcon} alt="Close Icon" />
      </CloseButton>
      {children}
    </MoreDetailsContainer>
  );
};

export default MoreDetails;
