import { useTranslation } from "react-i18next";
import { useInstantSearch } from "react-instantsearch";
import SaveIcon from "assets/icons/save_icon.svg";
import { useFetchSavedUiState, useSetSavedUiState } from "hooks/useSaveUiState";

const SaveSearchButton = () => {
  const { uiState } = useInstantSearch();
  const { t } = useTranslation();
  const { data: savedUiState, status } = useFetchSavedUiState();
  const { mutate: setSavedUIState } = useSetSavedUiState();

  const saveSearch = () => {
    const mergedUiState = {
      ...JSON.parse(savedUiState?.data as string),
      ...uiState,
    };
    setSavedUIState({ uiState: JSON.stringify(mergedUiState) });
    window.location.reload();
  };

  if (status === "loading" || status === "error") {
    return null;
  }

  return (
    <button type="button" onClick={saveSearch}>
      <img src={SaveIcon} alt="Save Icon" width={16} />
      {t("Save View")}
    </button>
  );
};

export default SaveSearchButton;
