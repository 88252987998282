// This list of countries is sourced from country-locale-map.
// The translations need to be kept in sync with the backend countries.ts
// TODO: share this file between frontend and backend.
export const countriesEnglish = {
  Afghanistan: "Afghanistan",
  Albania: "Albania",
  Algeria: "Algeria",
  "American Samoa": "American Samoa",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguilla",
  Antarctica: "Antarctica",
  "Antigua and Barbuda": "Antigua and Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaijan",
  Bahamas: "Bahamas",
  Bahrain: "Bahrain",
  Bangladesh: "Bangladesh",
  Barbados: "Barbados",
  Belarus: "Belarus",
  Belgium: "Belgium",
  Belize: "Belize",
  Benin: "Benin",
  Bermuda: "Bermuda",
  Bhutan: "Bhutan",
  Bolivia: "Bolivia",
  Bonaire: "Bonaire",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  Botswana: "Botswana",
  "Bouvet Island": "Bouvet Island",
  Brazil: "Brazil",
  "British Indian Ocean Territory": "British Indian Ocean Territory",
  "Brunei Darussalam": "Brunei Darussalam",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Cabo Verde": "Cabo Verde",
  Cambodia: "Cambodia",
  Cameroon: "Cameroon",
  Canada: "Canada",
  "Cayman Islands": "Cayman Islands",
  "Central African Republic": "Central African Republic",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  "Christmas Island": "Christmas Island",
  "Cocos Islands": "Cocos Islands",
  Colombia: "Colombia",
  Comoros: "Comoros",
  "Democratic Republic of the Congo": "Democratic Republic of the Congo",
  Congo: "Congo",
  "Cook Islands": "Cook Islands",
  "Costa Rica": "Costa Rica",
  Croatia: "Croatia",
  Cuba: "Cuba",
  Curaçao: "Curaçao",
  Cyprus: "Cyprus",
  Czechia: "Czechia",
  "Côte d'Ivoire": "Côte d'Ivoire",
  Denmark: "Denmark",
  Djibouti: "Djibouti",
  Dominica: "Dominica",
  "Dominican Republic": "Dominican Republic",
  Ecuador: "Ecuador",
  Egypt: "Egypt",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Equatorial Guinea",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Eswatini: "Eswatini",
  Ethiopia: "Ethiopia",
  "Falkland Islands": "Falkland Islands",
  "Faroe Islands": "Faroe Islands",
  Fiji: "Fiji",
  Finland: "Finland",
  France: "France",
  "French Guiana": "French Guiana",
  "French Polynesia": "French Polynesia",
  "French Southern Territories": "French Southern Territories",
  Gabon: "Gabon",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Germany",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Greece",
  Greenland: "Greenland",
  Grenada: "Grenada",
  Guadeloupe: "Guadeloupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  Guyana: "Guyana",
  Haiti: "Haiti",
  "Heard Island and McDonald Islands": "Heard Island and McDonald Islands",
  "Holy See": "Holy See",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Hungary",
  Iceland: "Iceland",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Iran",
  Iraq: "Iraq",
  Ireland: "Ireland",
  "Isle of Man": "Isle of Man",
  Israel: "Israel",
  Italy: "Italy",
  Jamaica: "Jamaica",
  Japan: "Japan",
  Jersey: "Jersey",
  Jordan: "Jordan",
  Kazakhstan: "Kazakhstan",
  Kenya: "Kenya",
  Kiribati: "Kiribati",
  "North Korea": "North Korea",
  "South Korea": "South Korea",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kyrgyzstan",
  "Lao People's Democratic Republic": "Lao People's Democratic Republic",
  Latvia: "Latvia",
  Lebanon: "Lebanon",
  Lesotho: "Lesotho",
  Liberia: "Liberia",
  Libya: "Libya",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lithuania",
  Luxembourg: "Luxembourg",
  Macao: "Macao",
  Madagascar: "Madagascar",
  Malawi: "Malawi",
  Malaysia: "Malaysia",
  Maldives: "Maldives",
  Mali: "Mali",
  Malta: "Malta",
  "Marshall Islands": "Marshall Islands",
  Martinique: "Martinique",
  Mauritania: "Mauritania",
  Mauritius: "Mauritius",
  Mayotte: "Mayotte",
  Mexico: "Mexico",
  Micronesia: "Micronesia",
  Moldova: "Moldova",
  Monaco: "Monaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Morocco",
  Mozambique: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Netherlands",
  "Netherlands Antilles": "Netherlands Antilles",
  "New Caledonia": "New Caledonia",
  "New Zealand": "New Zealand",
  Nicaragua: "Nicaragua",
  Niger: "Niger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Norfolk Island": "Norfolk Island",
  "North Macedonia": "North Macedonia",
  "Northern Mariana Islands": "Northern Mariana Islands",
  Norway: "Norway",
  Oman: "Oman",
  Pakistan: "Pakistan",
  Palau: "Palau",
  Palestine: "Palestine",
  Panama: "Panama",
  "Papua New Guinea": "Papua New Guinea",
  Paraguay: "Paraguay",
  Peru: "Peru",
  Philippines: "Philippines",
  Pitcairn: "Pitcairn",
  Poland: "Poland",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Qatar: "Qatar",
  Romania: "Romania",
  Russia: "Russia",
  Rwanda: "Rwanda",
  Réunion: "Réunion",
  "Saint Barthélemy": "Saint Barthélemy",
  "Saint Helena": "Saint Helena",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Martin": "Saint Martin",
  "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "Sao Tome and Principe",
  "Saudi Arabia": "Saudi Arabia",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leone",
  Singapore: "Singapore",
  "Sint Maarten": "Sint Maarten",
  Slovakia: "Slovakia",
  Slovenia: "Slovenia",
  "Solomon Islands": "Solomon Islands",
  Somalia: "Somalia",
  "South Africa": "South Africa",
  "South Georgia and the South Sandwich Islands": "South Georgia and the South Sandwich Islands",
  "South Sudan": "South Sudan",
  Spain: "Spain",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudan",
  Suriname: "Suriname",
  "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
  Sweden: "Sweden",
  Switzerland: "Switzerland",
  "Syrian Arab Republic": "Syrian Arab Republic",
  Taiwan: "Taiwan",
  Tajikistan: "Tajikistan",
  Tanzania: "Tanzania",
  Thailand: "Thailand",
  "Timor-Leste": "Timor-Leste",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad and Tobago",
  Tunisia: "Tunisia",
  Turkey: "Turkey",
  Turkmenistan: "Turkmenistan",
  "Turks and Caicos Islands": "Turks and Caicos Islands",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ukraine",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States Minor Outlying Islands": "United States Minor Outlying Islands",
  "United States of America": "United States of America",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistan",
  Vanuatu: "Vanuatu",
  Venezuela: "Venezuela",
  "Viet Nam": "Viet Nam",
  "Virgin Islands (British)": "Virgin Islands (British)",
  "Virgin Islands (U.S.)": "Virgin Islands (U.S.)",
  "Wallis and Futuna": "Wallis and Futuna",
  "Western Sahara": "Western Sahara",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabwe",
  "Åland Islands": "Åland Islands",
  Kosovo: "Kosovo",
} as const;

export const countriesSpanish: { [k in keyof typeof countriesEnglish]: string } = {
  Afghanistan: "Afganistán",
  Albania: "Albania",
  Algeria: "Argelia",
  "American Samoa": "Samoa Americana",
  Andorra: "Andorra",
  Angola: "Angola",
  Anguilla: "Anguila",
  Antarctica: "Antártida",
  "Antigua and Barbuda": "Antigua y Barbuda",
  Argentina: "Argentina",
  Armenia: "Armenia",
  Aruba: "Aruba",
  Australia: "Australia",
  Austria: "Austria",
  Azerbaijan: "Azerbaiyán",
  Bahamas: "Bahamas",
  Bahrain: "Baréin",
  Bangladesh: "Bangladés",
  Barbados: "Barbados",
  Belarus: "Bielorrusia",
  Belgium: "Bélgica",
  Belize: "Belice",
  Benin: "Benín",
  Bermuda: "Bermudas",
  Bhutan: "Bután",
  Bolivia: "Bolivia",
  Bonaire: "Bonaire",
  "Bosnia and Herzegovina": "Bosnia y Herzegovina",
  Botswana: "Botsuana",
  "Bouvet Island": "Isla Bouvet",
  Brazil: "Brasil",
  "British Indian Ocean Territory": "Territorio Británico del Océano Índico",
  "Brunei Darussalam": "Brunéi Darussalam",
  Bulgaria: "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  Burundi: "Burundi",
  "Cabo Verde": "Cabo Verde",
  Cambodia: "Camboya",
  Cameroon: "Camerún",
  Canada: "Canadá",
  "Cayman Islands": "Islas Caimán",
  "Central African Republic": "República Centroafricana",
  Chad: "Chad",
  Chile: "Chile",
  China: "China",
  "Christmas Island": "Isla de Navidad",
  "Cocos Islands": "Islas Cocos",
  Colombia: "Colombia",
  Comoros: "Comoras",
  "Democratic Republic of the Congo": "República Democrática del Congo",
  Congo: "Congo",
  "Cook Islands": "Islas Cook",
  "Costa Rica": "Costa Rica",
  Croatia: "Croacia",
  Cuba: "Cuba",
  Curaçao: "Curazao",
  Cyprus: "Chipre",
  Czechia: "Chequia",
  "Côte d'Ivoire": "Costa de Marfil",
  Denmark: "Dinamarca",
  Djibouti: "Yibuti",
  Dominica: "Dominica",
  "Dominican Republic": "República Dominicana",
  Ecuador: "Ecuador",
  Egypt: "Egipto",
  "El Salvador": "El Salvador",
  "Equatorial Guinea": "Guinea Ecuatorial",
  Eritrea: "Eritrea",
  Estonia: "Estonia",
  Eswatini: "Esuatini",
  Ethiopia: "Etiopía",
  "Falkland Islands": "Islas Malvinas",
  "Faroe Islands": "Islas Feroe",
  Fiji: "Fiyi",
  Finland: "Finlandia",
  France: "Francia",
  "French Guiana": "Guayana Francesa",
  "French Polynesia": "Polinesia Francesa",
  "French Southern Territories": "Territorios Australes Franceses",
  Gabon: "Gabón",
  Gambia: "Gambia",
  Georgia: "Georgia",
  Germany: "Alemania",
  Ghana: "Ghana",
  Gibraltar: "Gibraltar",
  Greece: "Grecia",
  Greenland: "Groenlandia",
  Grenada: "Granada",
  Guadeloupe: "Guadalupe",
  Guam: "Guam",
  Guatemala: "Guatemala",
  Guernsey: "Guernsey",
  Guinea: "Guinea",
  "Guinea-Bissau": "Guinea-Bisáu",
  Guyana: "Guyana",
  Haiti: "Haití",
  "Heard Island and McDonald Islands": "Islas Heard y McDonald",
  "Holy See": "Santa Sede",
  Honduras: "Honduras",
  "Hong Kong": "Hong Kong",
  Hungary: "Hungría",
  Iceland: "Islandia",
  India: "India",
  Indonesia: "Indonesia",
  Iran: "Irán",
  Iraq: "Irak",
  Ireland: "Irlanda",
  "Isle of Man": "Isla de Man",
  Israel: "Israel",
  Italy: "Italia",
  Jamaica: "Jamaica",
  Japan: "Japón",
  Jersey: "Jersey",
  Jordan: "Jordania",
  Kazakhstan: "Kazajistán",
  Kenya: "Kenia",
  Kiribati: "Kiribati",
  "North Korea": "Corea del Norte",
  "South Korea": "Corea del Sur",
  Kuwait: "Kuwait",
  Kyrgyzstan: "Kirguistán",
  "Lao People's Democratic Republic": "República Democrática Popular Lao",
  Latvia: "Letonia",
  Lebanon: "Líbano",
  Lesotho: "Lesoto",
  Liberia: "Liberia",
  Libya: "Libia",
  Liechtenstein: "Liechtenstein",
  Lithuania: "Lituania",
  Luxembourg: "Luxemburgo",
  Macao: "Macao",
  Madagascar: "Madagascar",
  Malawi: "Malaui",
  Malaysia: "Malasia",
  Maldives: "Maldivas",
  Mali: "Malí",
  Malta: "Malta",
  "Marshall Islands": "Islas Marshall",
  Martinique: "Martinica",
  Mauritania: "Mauritania",
  Mauritius: "Mauricio",
  Mayotte: "Mayotte",
  Mexico: "México",
  Micronesia: "Micronesia",
  Moldova: "Moldavia",
  Monaco: "Mónaco",
  Mongolia: "Mongolia",
  Montenegro: "Montenegro",
  Montserrat: "Montserrat",
  Morocco: "Marruecos",
  Mozambique: "Mozambique",
  Myanmar: "Myanmar",
  Namibia: "Namibia",
  Nauru: "Nauru",
  Nepal: "Nepal",
  Netherlands: "Países Bajos",
  "Netherlands Antilles": "Antillas Neerlandesas",
  "New Caledonia": "Nueva Caledonia",
  "New Zealand": "Nueva Zelanda",
  Nicaragua: "Nicaragua",
  Niger: "Níger",
  Nigeria: "Nigeria",
  Niue: "Niue",
  "Norfolk Island": "Isla Norfolk",
  "North Macedonia": "Macedonia del Norte",
  "Northern Mariana Islands": "Islas Marianas del Norte",
  Norway: "Noruega",
  Oman: "Omán",
  Pakistan: "Pakistán",
  Palau: "Palaos",
  Palestine: "Palestina",
  Panama: "Panamá",
  "Papua New Guinea": "Papúa Nueva Guinea",
  Paraguay: "Paraguay",
  Peru: "Perú",
  Philippines: "Filipinas",
  Pitcairn: "Islas Pitcairn",
  Poland: "Polonia",
  Portugal: "Portugal",
  "Puerto Rico": "Puerto Rico",
  Qatar: "Catar",
  Romania: "Rumania",
  Russia: "Rusia",
  Rwanda: "Ruanda",
  Réunion: "Reunión",
  "Saint Barthélemy": "San Bartolomé",
  "Saint Helena": "Santa Elena",
  "Saint Kitts and Nevis": "San Cristóbal y Nieves",
  "Saint Lucia": "Santa Lucía",
  "Saint Martin": "San Martín",
  "Saint Pierre and Miquelon": "San Pedro y Miquelón",
  "Saint Vincent and the Grenadines": "San Vicente y las Granadinas",
  Samoa: "Samoa",
  "San Marino": "San Marino",
  "Sao Tome and Principe": "Santo Tomé y Príncipe",
  "Saudi Arabia": "Arabia Saudita",
  Senegal: "Senegal",
  Serbia: "Serbia",
  Seychelles: "Seychelles",
  "Sierra Leone": "Sierra Leona",
  Singapore: "Singapur",
  "Sint Maarten": "Sint Maarten",
  Slovakia: "Eslovaquia",
  Slovenia: "Eslovenia",
  "Solomon Islands": "Islas Salomón",
  Somalia: "Somalia",
  "South Africa": "Sudáfrica",
  "South Georgia and the South Sandwich Islands": "Islas Georgias del Sur y Sandwich del Sur",
  "South Sudan": "Sudán del Sur",
  Spain: "España",
  "Sri Lanka": "Sri Lanka",
  Sudan: "Sudán",
  Suriname: "Surinam",
  "Svalbard and Jan Mayen": "Svalbard y Jan Mayen",
  Sweden: "Suecia",
  Switzerland: "Suiza",
  "Syrian Arab Republic": "República Árabe Siria",
  Taiwan: "Taiwán",
  Tajikistan: "Tayikistán",
  Tanzania: "Tanzania",
  Thailand: "Tailandia",
  "Timor-Leste": "Timor Oriental",
  Togo: "Togo",
  Tokelau: "Tokelau",
  Tonga: "Tonga",
  "Trinidad and Tobago": "Trinidad y Tobago",
  Tunisia: "Túnez",
  Turkey: "Turquía",
  Turkmenistan: "Turkmenistán",
  "Turks and Caicos Islands": "Islas Turcas y Caicos",
  Tuvalu: "Tuvalu",
  Uganda: "Uganda",
  Ukraine: "Ucrania",
  "United Arab Emirates": "Emiratos Árabes Unidos",
  "United Kingdom": "Reino Unido",
  "United States Minor Outlying Islands": "Islas Ultramarinas Menores de EE.UU.",
  "United States of America": "Estados Unidos de América",
  Uruguay: "Uruguay",
  Uzbekistan: "Uzbekistán",
  Vanuatu: "Vanuatu",
  Venezuela: "Venezuela",
  "Viet Nam": "Vietnam",
  "Virgin Islands (British)": "Islas Vírgenes Británicas",
  "Virgin Islands (U.S.)": "Islas Vírgenes de los Estados Unidos",
  "Wallis and Futuna": "Wallis y Futuna",
  "Western Sahara": "Sahara Occidental",
  Yemen: "Yemen",
  Zambia: "Zambia",
  Zimbabwe: "Zimbabue",
  "Åland Islands": "Islas Åland",
  Kosovo: "Kosovo",
} as const;
