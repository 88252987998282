import { useAuthInfo } from "@propelauth/react";
import { useEffect } from "react";
import { useAccessTokenStore } from "store/accessTokenStore";

export const useStoreAccessToken = () => {
  const { accessToken } = useAuthInfo();
  const { storedAccessToken, setAccessToken } = useAccessTokenStore();

  useEffect(() => {
    if (accessToken === storedAccessToken) return;
    if (!accessToken && storedAccessToken) {
      setAccessToken("");
      return;
    }
    if (!accessToken) return;
    setAccessToken(accessToken);
  }, [accessToken, storedAccessToken, setAccessToken]);
};
