import config from "config";
import { useTranslation } from "react-i18next";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import Dashboard from "components/MetabaseDashboard";
import { Container } from "./RailTracking.styled";

const { METABASE_RAIL_EVENTS_DASHBOARD_ID, METABASE_RAIL_EVENTS_SPANISH_DASHBOARD_ID } =
  config.envs;

const RailTracking = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("Rail Tracking"));
  const {
    i18n: { language },
  } = useTranslation();

  const dashboardId = Number(
    language === "es"
      ? METABASE_RAIL_EVENTS_SPANISH_DASHBOARD_ID
      : METABASE_RAIL_EVENTS_DASHBOARD_ID,
  );

  return (
    <Container data-testid="insights-page">
      <h1>{t("Rail Tracking")}</h1>
      <Dashboard dashboardId={Number(dashboardId)} />
    </Container>
  );
};

export default RailTracking;
