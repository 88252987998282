import { ReactNode } from "react";
import { useManageActiveTenant } from "hooks/useManageActiveTenant";
import { useStoreAccessToken } from "hooks/useStoreAccessToken";

type AuthSetupWrapperProps = {
  children: ReactNode;
};

const AuthSetupWrapper = ({ children }: AuthSetupWrapperProps) => {
  /* Actions to take when the user is authenticated and before the AppAuth routes are available */

  // Store the access token in local storage to be use in apiClient calls
  useStoreAccessToken();

  // Manage the active tenant as a cookie, also to be used in apiClient calls
  useManageActiveTenant();

  return <>{children}</>;
};

export default AuthSetupWrapper;
