import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/date";
import { hasExcelExtension, hasWordExtension } from "utils/string";
import { Panel } from "./DocumentsPanel.styled";
import ArrowRightLightIcon from "assets/icons/arrow_right_light_icon.svg";
import PDFIcon from "assets/icons/pdf_icon.svg";
import ExcelIcon from "assets/icons/excel_icon.svg";
import WordIcon from "assets/icons/word_icon.svg";
import { useCargoUnitStore } from "store/cargoUnitStore";
import { PreviewableDocumentGroup } from "types/cargoUnitTypes";

type StringBooleanMap = {
  [key: string]: boolean;
};

type DocumentPanelProps = {
  documents: PreviewableDocumentGroup[];
  setFile: (file: { fileId: string; fileName: string }) => void;
};

const DocumentsPanel = ({ documents, setFile }: DocumentPanelProps) => {
  const { cargoUnitId } = useCargoUnitStore();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [expandedDivs, setExpandedDivs] = useState<StringBooleanMap>({});
  const toggleExpand = (category: string): void => {
    setExpandedDivs((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const getFileIcon = (fileName: string) => {
    if (hasExcelExtension(fileName)) {
      return ExcelIcon;
    } else if (hasWordExtension(fileName)) {
      return WordIcon;
    } else {
      return PDFIcon;
    }
  };

  useEffect(() => {
    // Reset expandedDivs when cargoUnitId changes
    if (cargoUnitId === null) setExpandedDivs({});
  }, [cargoUnitId]);

  return (
    <Panel>
      <div className="header">
        <h4>{t("Received on")}</h4>
      </div>
      <div className="documentsList">
        {documents?.map((document, i) => (
          <div key={i}>
            <div key={document.type} className="documentType row">
              <span className="label">
                {t(document.type)} ({document.documents.length})
              </span>
              <img
                className={`arrowIcon ${expandedDivs[document.type] && "open"}`}
                src={ArrowRightLightIcon}
                alt="arrowIcon"
                onClick={() => {
                  toggleExpand(document.type);
                }}
              />
            </div>
            {expandedDivs[document.type] && (
              <div>
                {document.documents.map(({ referenceId, versions }, index) => (
                  <Fragment key={index}>
                    <div
                      className="referenceId row"
                      key={referenceId}
                      onClick={() => {
                        setFile({
                          fileId: versions[0].fileId,
                          fileName: versions[0].fileName,
                        });
                      }}
                    >
                      <span className="label">
                        <img
                          src={getFileIcon(versions[0].fileName)}
                          alt="File Icon"
                          className="fileIcon"
                        />
                        {document.type === "Other" ? versions[0].fileName : referenceId}
                      </span>
                      {document.type !== "Other" && (
                        <img
                          className={`arrowIcon ${expandedDivs[referenceId] && "open"}`}
                          src={ArrowRightLightIcon}
                          alt="arrowIcon"
                          onClick={() => {
                            toggleExpand(referenceId);
                          }}
                        />
                      )}
                    </div>
                    {document.type !== "Other" && expandedDivs[referenceId] && (
                      <div>
                        {versions.map((docVersion, j) => (
                          <div key={j}>
                            <div
                              className="fileName row"
                              key={j}
                              onClick={() => {
                                setFile({
                                  fileId: docVersion.fileId,
                                  fileName: docVersion.fileName,
                                });
                              }}
                            >
                              <span className="label">
                                <img
                                  src={getFileIcon(docVersion.fileName)}
                                  alt="File Icon"
                                  className="fileIcon"
                                />
                                {docVersion.fileName}
                              </span>
                              <span className="uploadDate">
                                {formatDate(new Date(docVersion.uploadDate), language)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default DocumentsPanel;
