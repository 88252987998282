import apiClient from "./apiClient";
import { SavedFilter } from "types/filterTypes";

export const getSavedFilter = async (): Promise<SavedFilter | null> => {
  const res = await apiClient.get("/search/filters");

  if (!res?.data) {
    return null;
  }

  return res.data;
};

export const upsertFilter = async (filterData: string): Promise<SavedFilter> => {
  const res = await apiClient.post("/search/filters", { data: filterData });

  return res.data;
};
